<template>
    None
</template>

<script>
export default {
  
  data(){
    return {
      componentTitle: null,
    }
  },
}
</script>
